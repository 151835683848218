<template>
  <form
    :id="'form-' + randomId"
    :class="
      $attrs.class ||
      twMerge(
        'max-w-sm mx-auto rounded p-5',
        appendClass
      )
    "
    :style="{
      boxShadow: '0px 4px 8px 0px #00000026',
    }"
    role="form"
    @submit.prevent="onSubmit"
    :data-testid="props.dataTestid"
  >
    <TsGrid :space="formSpace">
      <slot name="form-content" :form="form">
        <TsColumn class="px-0 py-0">
          <slot name="form-header" :form="form">
            <h2 :class="twMerge(['title', props.titleClass])">
              {{ formTitle }}
            </h2>
          </slot>
        </TsColumn>
        <slot name="form-body" :form="form" />

        <!-- Submit Button  -->
        <slot name="submit-button" :form="form">
          <TsButton
            :label="submitBtnLabel"
            type="submit"
            :icon="submitBtnIcon"
            block
            :disabled="props.submitBtnDisabled"
          />
        </slot>
        <slot name="form-footer" :form="form" />
      </slot>
    </TsGrid>
  </form>
</template>

<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { ref, reactive, onMounted } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";
type Form = {
  email?: string;
  password?: string;
  name?:
    | string
    | {
        firstName: string;
        lastName: string;
      };
  error?: string | Record<string, any>;
  pending?: boolean;
  titleClass?: string;
};

// Form - Store
const form = reactive<Form>({});

// Props
type Props = {
  formTitle?: string;
  titleClass?: string;
  submitBtnLabel?: string;
  submitBtnIcon?: string;
  appendClass?: ClassNameValue;
  formSpace?: number;
  autofocus?: boolean;
  submitBtnDisabled?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  formTitle: "Form Title",
  submitBtnLabel: "Submit",
  formSpace: 4,
  appendClass: "",
  autofocus: true,
  dataTestid: "organisms-form",
});

// Emits
const emit = defineEmits<{
  submit: [form: Form];
}>();

// Methods
const onSubmit = () => {
  emit("submit", form);
};

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();
});
</script>
